<template>
    <div id="home" class="home pa-4">
        <v-container>
            <v-layout row class="row-header">
                <v-flex xs12 md6 class="d-flex pt-1" :class="{ 'justify-center mb-4': $vuetify.breakpoint.smAndDown }">
                    <v-btn depressed rounded color="primary" class="mx-1" to="/add"><v-icon left>mdi-plus</v-icon>ΠΡΟΣΘΗΚΗ</v-btn>
                    <v-btn outlined rounded color="primary" class="mx-1" to="/import"><v-icon left>mdi-download</v-icon><template v-if="$vuetify.breakpoint.mdAndUp">ΕΙΣΑΓΩΓΗ </template>ΑΠΟ ΠΡΟΜΗΘΕΥΤΗ</v-btn>
                </v-flex>
                <v-flex xs12 md6 class="d-flex justify-end">
                    <v-text-field v-if="search_mode == 0" v-model="search" prepend-inner-icon="mdi-magnify" label="Αναζήτηση" clearable class="pt-0"></v-text-field>
                    <v-text-field v-else-if="search_mode == 1" v-model="sku" prepend-inner-icon="mdi-magnify" label="Αναζήτηση" clearable class="pt-0"></v-text-field>
                    <v-menu offset-y rounded="xl">
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" text rounded large dark class="ml-2" v-bind="attrs" v-on="on">
                            <v-icon v-if="search_mode == 0" left>mdi-shopping-search</v-icon>
                            <v-icon v-else-if="search_mode == 1" left>mdi-barcode-scan</v-icon>
                            <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                        </template>
                        <v-list style="min-width: 128px;" rounded>
                            <v-list-item v-for="(search_icon, index) in search_mode_list" :key="index" :input-value="index === search_mode" color="primary" class="justify-center" @click="search_mode = index; search = null; sku = null">
                                <v-list-item-avatar class="ma-0"><v-icon v-html="`mdi-${search_icon}`"></v-icon></v-list-item-avatar>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-flex>
            </v-layout>
            <v-layout row class="row-filters my-0">
                <v-flex xs12 v-if="$vuetify.breakpoint.mdAndUp" class="center-all justify-start">
                    <v-overflow-btn v-model="category" editable dense :items="flat_categories" :loading="!flat_categories" label="Κατηγορία" :disabled="!flat_categories" :append-outer-icon="category ? 'mdi-close' : null" @click:append-outer="category = null" item-text="name" item-value="id" rounded hide-details color="primary" class="mt-2 mr-4" style="max-width: 256px;"></v-overflow-btn>
                    <v-overflow-btn v-model="status" editable dense :items="status_list" :loading="!status_list" label="Κατάσταση" :disabled="!status_list" :append-outer-icon="status ? 'mdi-close' : null" @click:append-outer="status = null" item-text="text" item-value="value" rounded hide-details color="primary" class="mt-2 mr-4" style="max-width: 256px;"></v-overflow-btn>
                    <v-overflow-btn v-model="stock_status" editable dense :items="stock_status_list" :loading="!stock_status_list" label="Απόθεμα" :disabled="!stock_status_list" :append-outer-icon="stock_status ? 'mdi-close' : null" @click:append-outer="stock_status = null" item-text="text" item-value="value" rounded hide-details color="primary" class="mt-2 mr-4" style="max-width: 256px;"></v-overflow-btn>
                </v-flex>
                <template v-else>
                    <v-flex xs12 class="center-all">
                        <v-overflow-btn v-model="category" editable dense :items="flat_categories" :loading="!flat_categories" label="Κατηγορία" :disabled="!flat_categories" :append-outer-icon="category ? 'mdi-close' : null" @click:append-outer="category = null" item-text="name" item-value="id" rounded hide-details color="primary" style="max-width: 256px;"></v-overflow-btn>
                    </v-flex>
                    <v-flex xs12 class="center-all">
                        <v-overflow-btn v-model="status" editable dense :items="status_list" :loading="!status_list" label="Κατάσταση" :disabled="!status_list" :append-outer-icon="status ? 'mdi-close' : null" @click:append-outer="status = null" item-text="text" item-value="value" rounded hide-details color="primary" style="max-width: 256px;"></v-overflow-btn>
                    </v-flex>
                    <v-flex xs12 class="center-all">
                        <v-overflow-btn v-model="stock_status" editable dense :items="stock_status_list" :loading="!stock_status_list" label="Απόθεμα" :disabled="!stock_status_list" :append-outer-icon="stock_status ? 'mdi-close' : null" @click:append-outer="stock_status = null" item-text="text" item-value="value" rounded hide-details color="primary" style="max-width: 256px;"></v-overflow-btn>
                    </v-flex>
                </template>
            </v-layout>
            <v-layout row class="row-table-info">
                <v-flex xs12 md6 class="d-flex align-center">
                    <h5>Βλέπετε {{ pagination.from }} μέχρι {{ pagination.to }} από τα {{ pagination.total }} προϊόντα</h5>
                </v-flex>
                <v-flex xs12 md6 :class="{ 'text-right': $vuetify.breakpoint.mdAndUp }">
                    <h5 class="pr-2">Αποτελέσματα ανά σελίδα:</h5>
                    <v-select v-model="pagination.per_page" :items="[ 10, 20, 50, 100 ]" solo dense outlined flat rounded hide-details :disabled="loading" :menu-props="{ 'offset-y': true, rounded: 'xl' }" class="per-page custom-elevation mx-1" style="display: inline-block; width: 128px;"></v-select>
                </v-flex>
            </v-layout>
            <v-layout row class="row-table">
                <v-flex xs12>
                    <v-data-table :headers="columns" :items="products" :items-per-page="pagination.per_page" disable-sort class="custom-elevation rounded-lg" hide-default-footer :loading="loading" @click:row="selectProduct">
                        <template v-slot:item.id="{ item }">
                            #{{ item.id }}
                        </template>
                        <template v-slot:item.images="{ item }">
                            <img v-if="item.images && item.images.length > 0" :src="item.images[0].woocommerce_thumbnail || item.images[0].src" class="img-thumbnail rounded-lg" style="margin-top: 8px; width: 64px; height: 64px; object-fit: cover;"/>
                        </template>
                        <template v-slot:item.price="{ item }">
                            {{ item.price | price }}
                        </template>
                        <template v-slot:item.variations="{ item }">
                            <template v-if="!item.parent_id">{{ item.variations ? item.variations.length : 0 }}</template>
                            <template v-else>Παραλλαγή του #{{ item.parent_id }}</template>
                        </template>
                        <!-- <template v-slot:item.quantity="{ item }">
                            <span :class="getTotalQuantity(item) > 0 ? 'green--text' : 'red--text'">{{ getTotalQuantity(item) }}</span>
                        </template> -->
                        <template v-slot:item.categories="{ item }">
                            <template v-for="(category, i) in item.categories">
                                {{ category.name }}<template v-if="i + 1 < item.categories.length">, </template>
                            </template>
                        </template>
                        <template v-slot:item.date_created="{ item }">
                            <template v-if="item.status == 'draft'">Προσχέδιο</template>
                            <template v-else-if="item.status == 'pending'">Για έλεγχο</template>
                            <template v-else-if="item.status == 'private'">Ιδιωτικό</template>
                            <template v-else-if="item.status == 'publish'">Δημόσιο</template>
                            <br>
                            {{ item.date_created | moment('DD/MM/YY HH:mm') }}
                        </template>
                    </v-data-table>
                </v-flex>
            </v-layout>
            <v-layout row class="row-footer">
                <v-flex xs12 md6 v-if="$vuetify.breakpoint.mdAndUp" class="center-all justify-start">
                </v-flex>
                <v-flex xs12 md6>
                    <v-pagination v-model="pagination.current" :length="pagination.pages" circle :disabled="loading" :class="{ 'text-right': $vuetify.breakpoint.mdAndUp }"></v-pagination>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="product_dialog" max-width="900" scrollable content-class="rounded-xl">
            <v-card v-if="selected_product" :loading="loading">
                <v-card-title class="pb-0">
                    Προϊόν #{{ selected_product.id }}
                    <v-btn icon color="primary" :href="selected_product.permalink" target="_blank" class="ml-2"><v-icon>mdi-eye</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="product_dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-container fluid>
						<v-layout row class="my-0">
							<v-flex v-if="selected_product.images && selected_product.images.length > 0" xs12 md6 class="pa-2">
								<v-img :src="selected_product.images[0].woocommerce_thumbnail || selected_product.images[0].src" contain max-height="320" class="product-image elevation-4"></v-img>
							</v-flex>
							<v-flex xs12 md6 class="pa-2">
								<h2 class="primary--text">{{ selected_product.name }}</h2>
								<h3>
                                    <span v-for="(category, i) in selected_product.categories" :key="category.id">
                                        {{ category.name }}{{ i < selected_product.categories.length - 1 ? ', ' : '' }}
                                    </span>
                                </h3>
                                <p v-if="selected_product.parent_id">Παραλλαγή του #{{ selected_product.parent_id }}</p>
								<p v-html="selected_product.description" class="my-1"></p>
							</v-flex>
                            <template v-if="selected_product.type != 'simple'">
                                <v-flex v-if="!selected_product.parent_id" xs12 class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn text rounded small color="primary" @click="add_variation = true"><v-icon left>mdi-plus</v-icon>Προσθήκη Παραλλαγής</v-btn>
                                </v-flex>
                                <v-flex xs12 v-if="!selected_product_variations">
                                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                                </v-flex>
                                <v-flex xs12 v-else-if="selected_product_variations && selected_product_variations.length > 0">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">Barcode</th>
                                                    <th class="text-left">Μέγεθος</th>
                                                    <th class="text-left">Χρώμα</th>
                                                    <th class="text-left">Απόθεμα</th>
                                                    <th class="text-left">Τιμή</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(p_variation, i) in selected_product_variations" :key="i" :class="{ 'highlighted': p_variation.id == highlight_variation }">
                                                    <td>{{ p_variation.sku ? p_variation.sku : '-' }}</td>
                                                    <td>{{ p_variation.attributes && p_variation.attributes.find(attr => attr.name.toLowerCase() == 'size') ? p_variation.attributes.find(attr => attr.name.toLowerCase() == 'size').option : '-' }}</td>
                                                    <td>{{ p_variation.attributes && p_variation.attributes.find(attr => attr.name.toLowerCase() == 'color') ? p_variation.attributes.find(attr => attr.name.toLowerCase() == 'color').option : '-' }}</td>
                                                    <td>{{ p_variation.stock_quantity ? p_variation.stock_quantity : '-' }}</td>
                                                    <td>
                                                        <template v-if="p_variation.regular_price">{{ p_variation.regular_price | price }}</template>
                                                        <template v-else>-</template>
                                                    </td>
                                                    <td class="text-center">
                                                        <v-btn icon color="primary" class="mx-1" @click="edit_variation = p_variation"><v-icon>mdi-pencil</v-icon></v-btn>
                                                        <v-btn icon color="red" class="mx-1" @click="delete_variation = p_variation"><v-icon>mdi-delete</v-icon></v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-flex>
                            </template>
                            <p v-else class="mx-3 mb-0">
                                <strong>Τιμή:</strong> {{ selected_product.regular_price | price }}<br>
                                <strong>Ποσότητα:</strong> {{ selected_product.stock_quantity }}
                            </p>
						</v-layout>
					</v-container>
                </v-card-text>
                <v-card-actions v-if="!selected_product.parent_id">
                    <v-btn text rounded color="red" @click="delete_product = selected_product"><v-icon left>mdi-delete</v-icon>Διαγραφή</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn depressed rounded color="primary" :to="`/edit/${selected_product.id}`"><v-icon left>mdi-pencil</v-icon>Επεξεργασία Προϊόντος</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <DeleteDialog v-model="delete_dialog" :name="delete_product ? delete_product.name : null" @close="delete_dialog = false" @delete="deleteProduct"></DeleteDialog>

        <AddVariation v-model="add_variation" :product="selected_product" @reload="getProducts()"></AddVariation>
        <EditVariation v-model="edit_variation" :product="selected_product" @reload="getProducts()"></EditVariation>
        <DeleteDialog v-model="delete_variation_dialog" :name="delete_variation ? delete_variation.name : null" @close="delete_variation_dialog = false" @delete="deleteVariation"></DeleteDialog>
    </div>
</template>

<style scoped>
.row:not(.row-header) {
    margin: 1rem 0;
}
.row-header {
    margin: 0;
}

.row-table-info {
    padding: 0 0.5rem;
    margin: 0.5rem 0 !important;
}
.row-table-info h5 {
    display: inline-block;
    font-weight: 400;
    line-height: 40px;
}

.row-table .v-data-table {
    overflow: auto;
    font-weight: 500;
}

.product-image {
	border-radius: 8px;
}
.highlighted {
    background-color: #deeeff;
}
</style>

<style>
.home .v-data-table > .v-data-table__wrapper > table > tbody > tr {
    cursor: pointer;
}
.v-overflow-btn .v-input__append-outer {
    margin-top: 10px !important;
}
.v-overflow-btn .v-input__append-inner {
	margin-top: 0 !important;
    height: 100%;
}
</style>

<script>
import AddVariation from '@/components/AddVariation'
import EditVariation from '@/components/EditVariation'
import DeleteDialog from '@/components/Layout/DeleteDialog'
export default {
    name: 'Home',
    components: {
        AddVariation,
        EditVariation,
        DeleteDialog
    },
    data: () => ({
        loading: false,
        pagination: {
            current: 1,
            pages: 1,
            per_page: 20,

            from: 0,
            to: 0,
            total: 0
        },
        columns: [
            {
                text: 'ID',
                value: 'id',
            },
            {
                text: 'Εικόνα',
                value: 'images',
            },
            {
                text: 'Όνομα',
                value: 'name',
            },
            {
                text: 'Barcode',
                value: 'sku',
            },
            {
                text: 'Παραλλαγές',
                value: 'variations',
                align: 'center'
            },
            // {
            //     text: 'Απόθεμα',
            //     value: 'quantity',
            //     align: 'center'
            // },
            {
                text: 'Τιμή',
                value: 'price',
                align: 'center'
            },
            {
                text: 'Κατηγορία',
                value: 'categories',
            },
            {
                text: 'Ημερομηνία',
                value: 'date_created',
                align: 'center'
            }
        ],
        products: [],

        search: null,
        search_mode: 1,
        search_mode_list: [ 'shopping-search', 'barcode-scan' ],
        sku: null,
        status: null,
        status_list: [
            { text: 'Προσχέδιο', value: 'draft' },
            { text: 'Για έλεγχο', value: 'pending' },
            { text: 'Ιδιωτικό', value: 'private' },
            { text: 'Δημόσιο', value: 'publish' }
        ],
        stock_status: null,
        stock_status_list: [
            { text: 'Σε απόθεμα', value: 'instock' },
            { text: 'Εκτός αποθέματος', value: 'outofstock' },
            { text: 'Σε προπαραγγελία', value: 'onbackorder' }
        ],
        category: null,
        categories: null,
        stock_status: null,

        selected_product: null,
        delete_product: null,
        selected_product_variations: null,
        highlight_variation: null,
        add_variation: false,
        edit_variation: null,
        delete_variation: null
    }),
    computed: {
		flat_categories () {
			return this.$flattenCategories(this.categories)
		},
        product_dialog: {
            get () {
                return !!this.selected_product
            },
            set (v) {
                if (!v) this.selected_product = null
            }
        },
        delete_dialog: {
            get () {
                return !!this.delete_product
            },
            set (v) {
                if (v) return
                this.delete_product = null
            }
        },
        delete_variation_dialog: {
            get () {
                return !!this.delete_variation
            },
            set (v) {
                if (v) return
                this.delete_variation = null
            }
        }
    },
    mounted () {
        this.getProducts()
        this.getCategories()
    },
    watch: {
        'pagination.current' () {
            this.products = []
            this.getProducts()
        },
        'pagination.per_page' () {
            this.pagination.current = 1
            this.products = []
            this.getProducts()
        },
        search () {
            this.pagination.current = 1
            this.products = []
            this.getProducts()
        },
        sku () {
            this.pagination.current = 1
            this.products = []
            this.getProducts()
        },
        category () {
            this.pagination.current = 1
            this.products = []
            this.getProducts()
        },
        status () {
            this.pagination.current = 1
            this.products = []
            this.getProducts()
        },
        stock_status () {
            this.pagination.current = 1
            this.products = []
            this.getProducts()
        },
        selected_product (v) {
            if (v) {
                this.getSelectedProductVariations()
            } else {
                this.selected_product_variations = null
                this.highlight_variation = null
            }
        }
    },
    methods: {
        getProducts () {
            this.loading = true

            let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
                page: this.pagination.current,
                per_page: this.pagination.per_page,
                search: !!this.search && this.search_mode == 0 ? this.search : null,
                sku: !!this.sku && this.search_mode == 1 ? this.sku : null,
                status: !!this.status ? this.status : null,
                category: !!this.category ? this.category : null,
                stock_status: !!this.stock_status ? this.stock_status : null
            }

            this.axios.post('get-products', data)
                .then(resp => {
                    this.loading = false
                    let response = resp.data.items
                    this.products = response.products

                    this.pagination.pages = parseInt(response.totalPages)
                    this.pagination.total = parseInt(response.totalProducts)
                    this.pagination.from = response.totalProducts == 0 ? 0 : this.pagination.per_page * (this.pagination.current - 1) + 1
                    this.pagination.to = this.pagination.per_page * this.pagination.current > this.pagination.total ? this.pagination.total : this.pagination.per_page * this.pagination.current

                    if (this.selected_product) this.selected_product = this.products.find(p => p.id == this.selected_product.id)
                })
                .catch(err => {
                    this.loading = false
                })
        },
        getTotalQuantity (item) {
            let total = item.stock_quantity ? item.stock_quantity : 0
            item.variations.forEach(variation => {
                if (variation.stock_quantity) total += variation.stock_quantity
            })
            return total
        },
        getCategories (page = 1) {
			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}
			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				page
			}
			this.axios.post('store-categories', data)
				.then(resp => {
						// console.log('we are at page ' + page)
					if (page > 1) {
						// console.log('mpike sto if')
						this.categories = this.categories.concat(resp.data.categories)
					} else {
						// console.log('mpike sto else')
						this.categories = resp.data.categories
					}
					if (this.$flattenCategories(resp.data.categories).length > 99) this.loadWooCategories(page + 1)
					console.log('Store Categories loaded successfully.')
				})
				.catch(err => {
					this.$store.state.error = err
				})
		},
        deleteProduct () {
			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}
			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				product_id: this.delete_product?.id
			}
			this.axios.post('delete-product', data)
				.then(resp => {
                    this.delete_dialog = false
                    this.getProducts()
				})
				.catch(err => {
					this.$store.state.error = err
				})
        },
        deleteVariation () {
			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}

            if (!this.delete_variation || !this.delete_variation.id) {
                return this.$store.state.error = new Error('Προέκυψε κάποιο πρόβλημα με την διαγραφή αυτής της παραλλαγής.')
            }

			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				product_id: this.selected_product?.id,
                variation_id: this.delete_variation.id
			}
			this.axios.post('delete-variation', data)
				.then(resp => {
                    this.delete_variation_dialog = false
                    this.getProducts()
				})
				.catch(err => {
					this.$store.state.error = err
				})
        },
        getSelectedProductVariations () {
			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}
			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				product_id: this.selected_product?.id
			}
            this.axios.post('get-product-variations', data)
				.then(resp => {
                    this.selected_product_variations = resp.data.variations
				})
				.catch(err => {
					this.$store.state.error = err
				})
        },
        selectProduct (item) {
            if (!item.parent_id) {
                this.selected_product = item
            } else {
                this.loading = true
                this.selected_product = item
                let data = {
                    url: localStorage.getItem('wp_site_url'),
                    consumer_key: localStorage.getItem('wp_consumer_key'),
                    consumer_secret: localStorage.getItem('wp_consumer_secret'),
                    product_id: item.parent_id
                }
                this.axios.post('get-product', data)
                    .then(resp => {
                        this.selected_product = resp.data.items
                        this.highlight_variation = item.id
                        this.loading = false
                    })
                    .catch(err => {
                        this.$store.state.error = err
                    })
            }
        }
    }
}
</script>