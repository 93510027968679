<template>
    <v-dialog v-model="dialog" :persistent="add_attr_loading" content-class="rounded-xl" max-width="600">
        <v-card rounded="xl" :disabled="add_attr_loading">
			<v-card-title>{{ comp_attribute.title }}</v-card-title>
            <v-card-text>
                <v-text-field v-model="add_attr_option" rounded filled :label="comp_attribute.label"></v-text-field>
            </v-card-text>
            <v-card-actions class="px-2">
                <v-btn rounded outlined color="primary" :disabled="add_attr_loading" @click="dialog = false; add_attr_option = null">Ακύρωση</v-btn>
                <v-spacer></v-spacer>
                <v-btn rounded depressed color="primary" :loading="add_attr_loading" @click="addAttributeOption()">Προσθήκη</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>

</style>

<script>
export default {
    name: 'AddAttributeDialog',
    props: {
        value: {
            type: Boolean,
            required: true,
			default: false
        },
		attribute: {
			type: String,
			required: true
		},
		attr_id: {
			type: Number,
			required: true
		}
    },
    data: () => ({
		attributes: [
			{
				name: 'color',
				title: 'Προσθήκη όρου χρώματος',
				label: 'Όρος Χρώματος (π.χ. Red)'
			},
			{
				name: 'size',
				title: 'Προσθήκη όρου μεγέθους',
				label: 'Όρος Μεγέθους (π.χ. XL)'
			},
			{
				name: 'brand',
				title: 'Προσθήκη Μάρκας (Brand)',
				label: 'Μάρκα (π.χ. Nike)'
			}
		],
        add_attr_option: null,
		add_attr_loading: false
    }),
	computed: {
		dialog: {
			get () {
				return this.value
			},
			set (v) {
				this.$emit('input', v)
			}
		},
		comp_attribute () {
			return this.attributes.find(a => a.name == this.attribute)
		}
	},
    methods: {
        addAttributeOption () {
			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}

			this.add_attr_loading = true

			let attr_data = {
				id: this.attr_id,
				name: this.add_attr_option
			}

			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				data: attr_data
			}
			this.axios.post('add-attribute-term', data)
				.then(resp => {
					this.dialog = false
					this.add_attr_option = null
					this.add_attr_loading = false
					this.$emit('reloadAttributes')
				})
				.catch(err => {
					this.add_attr_loading = false
					this.$store.state.error = err
				})
		}
    }
}
</script>