import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMoment from 'vue-moment'

const moment = require('moment')
require('moment/locale/el')

Vue.prototype.$api_url = 'https://suppliers.cyberglitch.me/suppliers-api/'
// Vue.prototype.$api_url = 'https://dev.butler.gr:3000/suppliers-api/'

Vue.use(VueAxios, axios.create({
	baseURL: Vue.prototype.$api_url,
	withCredentials: true
}))

Vue.axios.interceptors.request.use((config) => {
	config.headers.Authorization = 'Bearer ' + localStorage.getItem('sup_token')
	return config
})

Vue.use(VueMoment, {
	moment
})
Vue.moment.locale('el')

Vue.config.productionTip = false
Vue.prototype.$appName = 'Suppliers'


Vue.prototype.$flattenCategories = (cat) => {
	if (!cat) return []
	let categories = JSON.parse(JSON.stringify(cat))

	let final_categories = []

	categories.forEach(category => {
		category.children.forEach(subcategory => {
			subcategory.name = `${category.name} / ${subcategory.name}`
			subcategory.children.forEach(subcategory2 => {
				subcategory2.name = `${subcategory.name} / ${subcategory2.name}`
				subcategory2.children.forEach(subcategory3 => {
					subcategory3.name = `${subcategory2.name} / ${subcategory3.name}`
					subcategory3.children.forEach(subcategory4 => {
						subcategory4.name = `${subcategory3.name} / ${subcategory4.name}`
						subcategory4.children.forEach(subcategory5 => {
							subcategory5.name = `${subcategory4.name} / ${subcategory5.name}`
							subcategory5.children.forEach(subcategory6 => {
								subcategory6.name = `${subcategory5.name} / ${subcategory6.name}`
								subcategory6.children.forEach(subcategory7 => {
									subcategory7.name = `${subcategory6.name} / ${subcategory7.name}`
									subcategory7.children.forEach(subcategory8 => {
										subcategory8.name = `${subcategory7.name} / ${subcategory8.name}`
									})
									if (subcategory7.children.length > 0) final_categories.push(subcategory7.children)
								})
								if (subcategory6.children.length > 0) final_categories.push(subcategory6.children)
							})
							if (subcategory5.children.length > 0) final_categories.push(subcategory5.children)
						})
						if (subcategory4.children.length > 0) final_categories.push(subcategory4.children)
					})
					if (subcategory3.children.length > 0) final_categories.push(subcategory3.children)
				})
				if (subcategory2.children.length > 0) final_categories.push(subcategory2.children)
			})
			if (subcategory.children.length > 0) final_categories.push(subcategory.children)
		})
		if (category.children.length > 0) final_categories.push(category.children)
	})
	if (categories.length > 0) final_categories.push(categories)

	final_categories = final_categories.flat()

	final_categories.forEach(category => {
		delete category.children
	})

	final_categories.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
	return final_categories
}

Vue.prototype.$debounce = (func, wait) => {
	let timeout

	return function executedFunction(...args) {
	const later = () => {
		clearTimeout(timeout)
		func(...args)
	}

	clearTimeout(timeout)
	timeout = setTimeout(later, wait)
	}
}

Vue.filter('price', (price) => {
	if (price == 0 || price == '0') return '0,00€'
	return price ? parseFloat(price).toLocaleString('el-GR', { minimumFractionDigits: 2 }) + '€' : parseFloat(price) + '€'
})

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
