<template>
    <div id="settings">
        <v-container>
            <v-card rounded="xl" class="my-4">
                <v-card-title>Ρυθμίσεις</v-card-title>
                <v-card-text>
                    <v-text-field v-model="wp_site_url" rounded filled dense label="Διεύθυνση Ιστοσελίδας WordPress"></v-text-field>
                    <v-text-field v-model="wp_consumer_key" rounded filled dense label="WooCommerce Consumer Key"></v-text-field>
                    <v-text-field v-model="wp_consumer_secret" rounded filled dense label="WooCommerce Consumer Secret"></v-text-field>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Settings',
    data: () => ({

    }),
    computed: {
        wp_site_url: {
            get () {
                return localStorage.getItem('wp_site_url')
            },
            set (v) {
                localStorage.setItem('wp_site_url', v)
            }
        },
        wp_consumer_key: {
            get () {
                return localStorage.getItem('wp_consumer_key')
            },
            set (v) {
                localStorage.setItem('wp_consumer_key', v)
            }
        },
        wp_consumer_secret: {
            get () {
                return localStorage.getItem('wp_consumer_secret')
            },
            set (v) {
                localStorage.setItem('wp_consumer_secret', v)
            }
        }
    }
}
</script>