<template>
    <v-dialog v-model="delete_dialog" class="delete_dialog" max-width="600" content-class="rounded-xl">
        <v-card>
            <v-card-title class="headline">Διαγραφή</v-card-title>
            <v-card-text v-if="name">
                Είστε βέβαιοι ότι θέλετε να διαγράψετε το {{ name }}; Αυτή η ενέργεια δεν μπορεί να αναιρεθεί.
            </v-card-text>
            <v-card-text v-else>
                Είστε βέβαιοι ότι θέλετε να διαγράψετε το αντικείμενο; Αυτή η ενέργεια δεν μπορεί να αναιρεθεί.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded text @click="delete_dialog = false">Όχι</v-btn>
                <v-btn rounded depressed color="red" dark @click="$emit('delete')">Ναι</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>

</style>

<script>
export default {
    name: 'DeleteDialog',
    components: {
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        name: {
            type: String,
            required: false
        }
    },
    data () {
		return {
		}
    },
    computed: {
        delete_dialog: {
            get: function () {
                return this.value
            },
            set: function (newValue) {
                if(newValue == false) {
                    this.$emit('close')
                }
            }
        }
    },
    methods: {
    }
}
</script>