<template>
	<div id="supplier-import">
		<v-container>
			<v-card rounded="xl" :disabled="search_loading" :loading="!flat_categories || flat_categories.length < 1 || !attributes || attributes.length < 1" class="my-4" @keyup.enter="search()">
				<v-card-title>Εισαγωγή από προμηθευτή</v-card-title>
				<v-card-text v-if="suppliers && suppliers.length > 0">
					<v-select v-model="selected_supplier" prepend-inner-icon="mdi-archive" rounded filled dense label="Επιλογή Προμηθευτή" :items="suppliers" item-text="name" item-value="codename" @change="() => { this.barcode_search = null; this.product_code_search = null }" :disabled="search_loading"></v-select>
					<v-text-field v-model="barcode_search" v-if="selected_supplier_obj && selected_supplier_obj.search_support.includes('barcode')" prepend-inner-icon="mdi-barcode" rounded filled dense label="Αναζήτηση με Barcode" clearable @change="() => { this.product_code_search = null }" :disabled="search_loading" ref="barcodeField"></v-text-field>
					<v-text-field v-model="product_code_search" v-if="selected_supplier_obj && selected_supplier_obj.search_support.includes('product_code')" prepend-inner-icon="mdi-numeric" rounded filled dense label="Αναζήτηση με Κωδικό Προϊόντος" clearable @change="() => { this.barcode_search = null }" :disabled="search_loading" ref="productCodeField"></v-text-field>
					<p v-if="selected_supplier == 'manual'" class="mb-0 px-4">
						<small>
							Έχεις επιλέξει χειροκίνητη εισαγωγή. Αν θες να προσθέσεις καινούριο προϊόν, πάτα κατευθείαν αναζήτηση. Αν θες να προσθέσεις μια παραλλαγή σε ένα προϊόν, πληκτρολόγησε πρώτα τον κωδικό του γονικού προϊόνοτος και μετά πάτα αναζήτηση.
						</small>
					</p>
				</v-card-text>
				<v-card-text v-else class="text-center">
					<v-progress-circular indeterminate color="primary"></v-progress-circular>
					<p class="my-6">Παρακαλώ περιμένετε να φορτώσει η λίστα με τους διαθέσιμους προμηθευτές</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn rounded depressed color="primary" class="px-4" :loading="search_loading" :disabled="!flat_categories || flat_categories.length < 1" @click="search()"><v-icon left>mdi-magnify</v-icon>Αναζήτηση</v-btn>
				</v-card-actions>
			</v-card>

			<v-card v-if="variation.items && variation.items.length > 0" rounded="xl" class="my-4">
				<v-card-title>{{ variation.items.length > 1 ? `Βρέθηκαν ${variation.items.length} ήδη υπάρχον προϊόντα` : `Βρέθηκε 1 ήδη υπάρχον προϊόν` }}</v-card-title>
				<v-card-text>
					<v-checkbox v-model="variation.import_as_variation" label="Προσθήκη νέου ως παραλλαγή του αρχικού προϊόντος" class="mt-0"></v-checkbox>
					<v-select v-if="variation.import_as_variation" v-model="variation.selected_item" :items="variation.items" item-text="name" item-value="id" hide-details rounded filled></v-select>
					<v-container fluid v-if="selected_variation_item">
						<v-layout row class="mt-2">
							<v-flex v-if="selected_variation_item.images && selected_variation_item.images.length > 0" xs12 md6 class="pa-2">
								<v-img :src="selected_variation_item.images[0].woocommerce_thumbnail || selected_variation_item.images[0].src" contain max-height="320" class="product-image elevation-4"></v-img>
							</v-flex>
							<v-flex xs12 md6 class="pa-2">
								<h2 class="primary--text">{{ selected_variation_item.name }}</h2>
								<h3><span v-for="(category, i) in selected_variation_item.categories" :key="category.id">{{ category.name }}{{ i < selected_variation_item.categories.length + 1 ? '' : ', ' }}</span></h3>
								<p v-html="selected_variation_item.description" class="my-1"></p>
							</v-flex>
							<v-flex xs12 v-if="selected_variation_item.variations && selected_variation_item.variations.length > 0">
								<v-simple-table>
									<template v-slot:default>
										<thead>
											<tr>
												<th class="text-left">Barcode</th>
												<th class="text-left">Μέγεθος</th>
												<th class="text-left">Χρώμα</th>
												<th class="text-left">Απόθεμα</th>
												<th class="text-left">Τιμή</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(p_variation, i) in selected_variation_item.variations" :key="i">
											<td>{{ p_variation.sku }}</td>
											<td>{{ p_variation.attributes.find(attr => attr.name.toLowerCase() == 'size') ? p_variation.attributes.find(attr => attr.name.toLowerCase() == 'size').option : '-' }}</td>
											<td>{{ p_variation.attributes.find(attr => attr.name.toLowerCase() == 'color') ? p_variation.attributes.find(attr => attr.name.toLowerCase() == 'color').option : '-' }}</td>
											<td>{{ p_variation.stock_quantity }}</td>
											<td>{{ p_variation.regular_price }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-text>
			</v-card>

			<v-card v-if="result_product && import_product" rounded="xl" class="my-4">
				<v-card-title>Προϊόν για εισαγωγή</v-card-title>
				<v-card-text>
					<v-form v-model="product_form" :disabled="import_loading">
						<template v-if="!variation.import_as_variation">
							<v-img v-if="image_preview != null && import_images[image_preview]" :src="import_images[image_preview]" contain max-height="480" class="ma-4 mt-0 product-image elevation-4"></v-img>
	                        <v-btn depressed rounded color="primary" class="mx-4 mb-4" @click="upload_image = true"><v-icon left>mdi-upload</v-icon>Ανέβασμα Εικόνας</v-btn>
							<v-slide-group v-if="image_preview != null" v-model="image_preview" class="pb-4" center-active>
								<v-slide-item v-for="(image, i) in import_images" :key="i" v-slot:default="{ active, toggle }">
									<div class="text-center">
										<v-card class="ma-4" height="100" width="100" @click="!active ? toggle() : null">
											<v-img :src="image" :gradient="active ? 'to bottom, rgba(79, 101, 142, 0.8), rgba(79, 101, 142, 0.8)' : ''" height="100" width="100"></v-img>
										</v-card>
										<v-btn icon color="primary" :disabled="!import_images[i - 1]" @click="arrayMove(import_images, i, i - 1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
										<v-btn icon color="primary" :disabled="!import_images[i + 1]" @click="arrayMove(import_images, i, i + 1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
										<v-btn icon color="red" @click="import_images.splice(i, 1)"><v-icon>mdi-delete</v-icon></v-btn>
									</div>
								</v-slide-item>
							</v-slide-group>
							<v-overflow-btn editable v-model="import_product.categories" rounded filled label="Κατηγορία" :items="flat_categories" item-text="name" item-value="id" multiple :menu-props="{ bottom: true }" chips :rules="[ v => !!v || 'Απαιτείται συμπλήρωση', v => v && v.length > 0 || 'Απαιτείται τουλάχιστον 1 επιλογή' ]"></v-overflow-btn>
							<v-text-field v-model="import_product.title" rounded filled dense label="Τίτλος" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-text-field>
							<v-textarea v-model="import_product.description" rounded filled dense label="Περιγραφή" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-textarea>
							<!-- <quill-editor v-model="import_product.description" :options="editorOption"/> -->
						</template>

						<v-container fluid>
							<v-layout row>
								<v-flex xs12 v-if="result_product.size">
										<p v-if="(import_product.size ? import_product.size.toString().toLowerCase() : '') !== result_product.size.toString().toLowerCase()" class="px-4 mb-1">Το μέγεθος που λήφθηκε από τον προμηθευτή ήταν: {{ result_product.size }}</p>
								</v-flex>
								<v-flex xs10 md11>
									<v-overflow-btn editable v-model="import_product.size" :items="attr_size_options" item-value="name" item-text="name" rounded filled label="Μέγεθος" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-overflow-btn>
								</v-flex>
								<v-flex xs2 md1 class="center-all">
									<v-btn icon @click="add_size_dialog = true" class="mb-6" :disabled="import_loading"><v-icon>mdi-plus</v-icon></v-btn>
								</v-flex>
							</v-layout>
						</v-container>

						<v-container fluid>
							<v-layout row>
								<v-flex xs12 v-if="result_product.color">
									<p v-if="(import_product.color ? import_product.color.toString().toLowerCase() : '') !== result_product.color.toString().toLowerCase()" class="px-4 mb-1">Το χρώμα που λήφθηκε από τον προμηθευτή ήταν: {{ result_product.color }}</p>
								</v-flex>
								<v-flex xs10 md11>
									<v-overflow-btn editable v-model="import_product.color" :items="attr_color_options" item-value="name" item-text="name" rounded filled label="Χρώμα" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-overflow-btn>
								</v-flex>
								<v-flex xs2 md1 class="center-all">
									<v-btn icon @click="add_color_dialog = true" class="mb-6" :disabled="import_loading"><v-icon>mdi-plus</v-icon></v-btn>
								</v-flex>
							</v-layout>
						</v-container>
						<v-text-field v-model="import_product.price" rounded filled dense label="Τιμή" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-text-field>
						<v-text-field v-model="import_product.quantity" rounded filled dense label="Ποσότητα" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-text-field>
						<v-text-field v-model="import_product.sku" rounded filled dense label="Barcode"></v-text-field>
						<template v-if="!variation.import_as_variation">
							<v-text-field v-model="import_product.product_code" rounded filled dense label="Κωδικός Προϊόντος" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-text-field>
						</template>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-btn rounded outlined color="primary" class="px-4" :disabled="import_loading" @click="resultProductToImportProduct()">Επαναφορά</v-btn>
					<v-spacer></v-spacer>
					<v-btn rounded depressed color="primary" class="px-4" :loading="import_loading" @click="importProduct()">Εισαγωγή</v-btn>
				</v-card-actions>
			</v-card>
			<v-card v-if="success" rounded="xl">
				<v-card-title>Το προϊόν προστέθηκε με επιτυχία!</v-card-title>
				<v-card-actions>
					<v-btn v-if="success.edit_url" rounded outlined color="primary" class="px-4" :href="success.edit_url" target="_blank">
						<v-icon left>mdi-pencil</v-icon>
						Επεξεργασία
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn v-if="success.view_url" rounded depressed color="primary" class="px-4" :href="success.view_url" target="_blank">
						<v-icon left>mdi-eye</v-icon>
						Προβολή
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>

        <ImageUpload v-model="upload_image" @uploaded="imageUploaded"></ImageUpload>
		<AddAttributeDialog v-if="attr_size && attr_size.id" v-model="add_size_dialog" attribute="size" :attr_id="attr_size.id" @reloadAttributes="loadWooAttributes()"></AddAttributeDialog>
		<AddAttributeDialog v-if="attr_color && attr_color.id" v-model="add_color_dialog" attribute="color" :attr_id="attr_color.id" @reloadAttributes="loadWooAttributes()"></AddAttributeDialog>
	</div>
</template>

<style scoped>
.product-image {
	border-radius: 8px;
}
</style>

<script>
import ImageUpload from '@/components/Layout/ImageUpload'
import AddAttributeDialog from '@/components/AddAttributeDialog'
export default {
	name: 'SupplierImport',
	components: {
		ImageUpload,
		AddAttributeDialog
	},
	data: () => ({
        upload_image: false,

		search_loading: false,
		manual_import: false,

		barcode_search: null,
		product_code_search: null,
		selected_supplier: null,

		result_product: null,
		import_product: {
			title: null,
			description: null,
			size: [],
			color: [],
			price: null,
			quantity: null,
			sku: null,
			product_code: null,
			image: null,
			images: [],
			categories: []
		},
		import_images: [],
		product_form: true,
		image_preview: 0,

		categories: [],
		attributes: [],

		variation: {
			items: [],
			selected_item: null,
			import_as_variation: false
		},

		add_size_dialog: false,
		add_color_dialog: false,

		import_loading: false,

		success: null
	}),
	computed: {
		suppliers () {
			return this.$store.state.suppliers
		},
		flat_categories () {
			return this.$flattenCategories(this.categories)
		},
		selected_supplier_obj () {
			return this.$store.state.suppliers.find(s => s.codename == this.selected_supplier)
		},
		selected_variation_item () {
			return this.variation.items.find(item => item.id == this.variation.selected_item)
		},
		attr_color () {
			return this.attributes.find(attr => attr.name.toLowerCase() == 'color')
		},
		attr_color_options () {
			return this.attr_color?.options?.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) || []
		},
		attr_size () {
			return this.attributes.find(attr => attr.name.toLowerCase() == 'size')
		},
		attr_size_options () {
			return this.attr_size?.options?.sort((a,b) => (a.name > b.name) ? -1 : ((b.name > a.name) ? 1 : 0)) || []
		}
	},
	watch: {
		'variation.import_as_variation' (v) {
			if (v) {
				if (this.variation.items?.length > 0) this.variation.selected_item = this.variation.items[0].id
			} else {
				this.variation.selected_item = null
			}
		}
	},
	mounted () {
		this.loadWooCategories()
		this.loadWooAttributes()
		// this.download_image('http://localhost:8080/logo.png')
	},
	methods: {
		search () {
			this.result_product = null
			this.import_product = null
			this.success = null

			this.search_loading = true

			let data = {}
			if (this.selected_supplier == 'manual') {
				this.result_product = {
					title: null,
					description: null,
					size: [],
					color: [],
					price: null,
					quantity: null,
					sku: null,
					product_code: null,
					image: null,
					images: [],
					categories: []
				}
				if (this.product_code_search) {
					this.result_product.product_code = this.product_code_search
					this.searchIfExists()
				} else {
					this.resultProductToImportProduct()
					this.search_loading = false
				}
				return
			} else if (this.barcode_search) {
				data.barcode = this.barcode_search
			} else if (this.product_code_search) {
				data.product_code = this.product_code_search
			} else {
				this.search_loading = false
				return this.$store.state.error = new Error('Δεν έχετε δηλώσει κανέναν όρο αναζήτησης!')
			}

			this.axios.post(this.selected_supplier, data)
				.then(resp => {
					this.result_product = resp.data.item
					if (!this.result_product.sku && this.barcode_search) this.result_product.sku = this.barcode_search
					if (!this.result_product.product_code && this.product_code_search) this.result_product.product_code = this.product_code_search
					if (this.result_product.title) {
						this.result_product.title = `${this.selected_supplier_obj.name} ${this.result_product.title}`
					} else {
						this.result_product.title = `${this.selected_supplier_obj.name} ${this.result_product.description}`
					}

					if (this.result_product.product_code) {
						this.searchIfExists()
					} else {
						this.resultProductToImportProduct()
					}
					this.search_loading = false
				})
				.catch(err => {
					this.search_loading = false
					this.$store.state.error = err
				})
		},
		resultProductToImportProduct () {
			this.import_product = { ...this.result_product }
			if (this.result_product.color && this.attr_color_options.find(c => c.name?.toString().toLowerCase() == this.result_product.color.toString().toLowerCase())) {
				this.import_product.color = this.attr_color_options.find(c => c.name?.toString().toLowerCase() == this.result_product.color.toString().toLowerCase()).name
			} else {
				this.import_product.color = null
			}
			// this.result_product.size = `${this.result_product.size}`
			if (this.result_product.size && this.attr_size_options.find(c => c.name?.toString().toLowerCase() == this.result_product.size.toString().toLowerCase())) {
				this.import_product.size = this.attr_size_options.find(c => c.name?.toString().toLowerCase() == this.result_product.size.toString().toLowerCase()).name
			} else {
				this.import_product.size = null
			}
			this.copyImportImages()
		},
		searchIfExists() {
			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				product_code: this.result_product.product_code
			}
			this.axios.post('search-product', data)
				.then(resp => {
					this.variation.items = resp.data.items
					if (this.variation.items.length > 0) {
						this.variation.import_as_variation = true
						// this.variation.selected_item = this.variation.items[0].id
					}
					this.resultProductToImportProduct()
				})
				.catch(err => {
					this.search_loading = false
					this.$store.state.error = err
				})
		},
		loadWooCategories (page = 1) {
			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}
			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				page
			}
			this.axios.post('store-categories', data)
				.then(resp => {
						// console.log('we are at page ' + page)
					if (page > 1) {
						// console.log('mpike sto if')
						this.categories = this.categories.concat(resp.data.categories)
					} else {
						// console.log('mpike sto else')
						this.categories = resp.data.categories
					}
					if (this.$flattenCategories(resp.data.categories).length > 99) this.loadWooCategories(page + 1)
					console.log('Store Categories loaded successfully.')
				})
				.catch(err => {
					this.$store.state.error = err
				})
		},
		loadWooAttributes () {
			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}
			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret')
			}
			this.axios.post('get-attributes', data)
				.then(resp => {
					this.attributes = resp.data.attributes
					console.log('Product Attributes loaded successfully.')
				})
				.catch(err => {
					this.$store.state.error = err
				})
		},
		arrayMove (arr, old_index, new_index) {
			while (old_index < 0) {
				old_index += arr.length
			}
			while (new_index < 0) {
				new_index += arr.length
			}
			if (new_index >= arr.length) {
				var k = new_index - arr.length + 1
				while (k--) {
					arr.push(undefined)
				}
			}
			arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
			return arr
		},
		copyImportImages () {
			let image_exist = this.import_product.image
			let images_exist = this.import_product.images && this.import_product.images.length > 0
			if (image_exist && !images_exist) {
				let images = []
				images.unshift(this.import_product.image)
				this.import_images = images
			} else if (!image_exist && images_exist) {
				this.import_images = [ ...this.import_product.images ]
			} else if (image_exist && images_exist) {
				let images = [ ...this.import_product.images ]
				images.unshift(this.import_product.image)
				this.import_images = images
			} else {
				this.import_images = []
			}
			this.search_loading = false
		},
		// download_image (image_url) {
		// 	this.axios.get(image_url, { responseType: 'stream' })
		// 		.then(resp => {
		// 			this.upload_image(resp.data)
		// 		})
		// },
		// upload_image (image) {
		// 	let formData = new FormData()
		// 	formData.append('title', 'test title')
		// 	formData.append('caption', 'test caption')
		// 	formData.append('file', image)

		// 	this.axios.post(localStorage.getItem('wp_site_url') + 'wp-json/wp/v2/media', formData, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + window.btoa('secretadmin:ktGht3ViHLjaiWdnLFsdaoql')
		// 		}})
		// 		.then(resp => {
		// 			console.log(resp)
		// 		})
		// 		.catch(err =>{
		// 			console.log(err)
		// 		})
		// },
        imageUploaded (images) {
            this.import_images = this.import_images.concat(images)
        },
		importProduct () {
			if (!this.product_form) return this.$store.state.error = new Error('Πρέπει να συμπληρώσεις όλα τα απαραίτητα πεδία!')
			this.import_loading = true

			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}

			let product_data = { ...this.import_product }
			product_data.image = this.import_images[0]
			product_data.images = [ ...this.import_images ]
			product_data.images.shift()

			product_data.quantity = product_data.quantity ? parseInt(product_data.quantity) : 0

			if (product_data.product_code) product_data.title = `${product_data.title} - ${product_data.product_code}`

			product_data.color = {
				id: this.attr_color.id,
				name: this.attr_color.name,
				option: product_data.color
			}

			product_data.size = {
				id: this.attr_size.id,
				name: this.attr_size.name,
				option: product_data.size
			}

			if (this.variation.import_as_variation) product_data.product_id = this.selected_variation_item.id

			// delete product_data.images

			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				data: product_data
			}

			if (this.selected_supplier_obj.download == false) data.download = false

			let url = this.variation.import_as_variation ? 'add-variation' : 'add-product'

			this.axios.post(url, data)
				.then(resp => {
					// console.log(resp.data)
					this.result_product = null
					this.import_product = null
					this.import_loading = false
					// this.product_code_search = null
					// this.barcode_search = null

					this.variation = {
						items: [],
						selected_item: null,
						import_as_variation: false
					}

					this.success = {
						edit_url: `${localStorage.getItem('wp_site_url')}wp-admin/post.php?post=${resp.data.product.id}&action=edit`,
						view_url: resp.data.product.permalink
					}

					if (this.$refs.barcodeField) this.$refs.barcodeField.$refs.input.focus()
					if (this.$refs.productCodeField) this.$refs.productCodeField.$refs.input.focus()
				})
				.catch(err => {
					this.$store.state.error = err
					this.import_loading = false
				})
		}
	}
}
</script>
