<template>
	<div id="edit-product">
		<v-container>
			<v-card v-if="!success" rounded="xl" class="my-4" :loading="!flat_categories || flat_categories.length < 1 || !attributes || attributes.length < 1">
				<v-card-title>Επεξεργασία προϊόντος</v-card-title>
				<v-card-text v-if="flat_categories && flat_categories.length > 0 && attributes && attributes.length > 1" class="pt-0">
					<v-form v-model="product_form" :disabled="import_loading">
                        <v-img v-if="image_preview != null && import_images[image_preview]" :src="import_images[image_preview]" contain max-height="480" class="ma-4 mt-0 product-image elevation-4"></v-img>
                        <v-btn depressed rounded color="primary" class="mx-4 mb-4" @click="upload_image = true"><v-icon left>mdi-upload</v-icon>Ανέβασμα Εικόνας</v-btn>
                        <v-slide-group v-if="image_preview != null && import_images[image_preview]" v-model="image_preview" class="pb-4" center-active>
                            <v-slide-item v-for="(image, i) in import_images" :key="i" v-slot:default="{ active, toggle }">
                                <div class="text-center">
                                    <v-card class="ma-4" height="100" width="100" @click="!active ? toggle() : null">
                                        <v-img :src="image" :gradient="active ? 'to bottom, rgba(79, 101, 142, 0.8), rgba(79, 101, 142, 0.8)' : ''" height="100" width="100"></v-img>
                                    </v-card>
                                    <v-btn icon color="primary" :disabled="!import_images[i - 1]" @click="arrayMove(import_images, i, i - 1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
                                    <v-btn icon color="primary" :disabled="!import_images[i + 1]" @click="arrayMove(import_images, i, i + 1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
                                    <v-btn icon color="red" @click="import_images.splice(i, 1)"><v-icon>mdi-delete</v-icon></v-btn>
                                </div>
                            </v-slide-item>
                        </v-slide-group>
                        <v-overflow-btn editable v-model="import_product.categories" rounded filled label="Κατηγορία" :items="flat_categories" item-text="name" item-value="id" multiple :menu-props="{ bottom: true }" chips :rules="[ v => !!v || 'Απαιτείται συμπλήρωση', v => v && v.length > 0 || 'Απαιτείται τουλάχιστον 1 επιλογή' ]"></v-overflow-btn>
						<v-container fluid>
							<v-layout row>
								<v-flex xs10 md11>
									<v-overflow-btn editable v-model="import_product.brand" :items="attr_brand_options" item-value="name" item-text="name" rounded filled label="Μάρκα (Brand)" clearable></v-overflow-btn>
								</v-flex>
								<v-flex xs2 md1 class="center-all">
									<v-btn icon @click="add_brand_dialog = true" class="mb-6" :disabled="import_loading"><v-icon>mdi-plus</v-icon></v-btn>
								</v-flex>
							</v-layout>
						</v-container>
                        <v-text-field v-model="import_product.title" rounded filled dense label="Τίτλος" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-text-field>
                        <v-textarea v-model="import_product.description" rounded filled dense label="Περιγραφή" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-textarea>

                        <v-text-field v-model="import_product.product_code" rounded filled dense label="Κωδικός Προϊόντος" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-text-field>

						<template v-if="import_product.type == 'simple'">
							<v-text-field v-model="import_product.regular_price" rounded filled dense label="Τιμή" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-text-field>
							<v-text-field v-model="import_product.stock_quantity" rounded filled dense label="Ποσότητα" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-text-field>
						</template>
					</v-form>
				</v-card-text>
				<v-card-actions v-if="flat_categories && flat_categories.length > 0 && attributes && attributes.length > 1">
					<v-spacer></v-spacer>
					<v-btn rounded depressed color="primary" class="px-4" :loading="import_loading" @click="importProduct()">Ενημέρωση</v-btn>
				</v-card-actions>
			</v-card>
			<v-card v-if="success" rounded="xl">
				<v-card-title>Το προϊόν ενημερώθηκε με επιτυχία!</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn v-if="success.view_url" rounded depressed color="primary" class="px-4" :href="success.view_url" target="_blank">
						<v-icon left>mdi-eye</v-icon>
						Προβολή
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>

        <ImageUpload v-model="upload_image" @uploaded="imageUploaded"></ImageUpload>
		<AddAttributeDialog v-if="attr_brand && attr_brand.id" v-model="add_brand_dialog" attribute="brand" :attr_id="attr_brand.id" @reloadAttributes="loadWooAttributes()"></AddAttributeDialog>
	</div>
</template>

<style scoped>
.product-image {
	border-radius: 8px;
}
</style>

<script>
import ImageUpload from '@/components/Layout/ImageUpload'
import AddAttributeDialog from '@/components/AddAttributeDialog'
export default {
	name: 'EditProduct',
	components: {
		ImageUpload,
		AddAttributeDialog
	},
	data: () => ({
        upload_image: false,

		import_product: {
			brand: null,
			title: null,
			description: null,
			product_code: null,
			image: null,
			images: [],
			categories: [],

			type: null,
			regular_price: null,
			stock_quantity: null,
		},
		import_images: [],
		product_form: true,
		image_preview: 0,

		categories: [],
		attributes: [],

		add_brand_dialog: false,

		import_loading: false,

		success: null
	}),
	computed: {
		suppliers () {
			return this.$store.state.suppliers
		},
		flat_categories () {
			return this.$flattenCategories(this.categories)
		},
		selected_supplier_obj () {
			return this.$store.state.suppliers.find(s => s.codename == this.selected_supplier)
		},
		selected_variation_item () {
			return this.variation.items.find(item => item.id == this.variation.selected_item)
		},
		attr_brand () {
			return this.attributes?.find(attr => attr.name.toLowerCase() == 'brand')
		},
		attr_brand_options () {
			return this.attr_brand?.options.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
		}
	},
	mounted () {
		this.getProduct(this.$route.params.product_id)
		this.loadWooCategories()
		this.loadWooAttributes()
	},
	methods: {
		reset () {
            this.import_product = {
				brand: null,
                title: null,
				description: null,
				product_code: null,
				image: null,
				images: [],
				categories: []
            }
		},
		getProduct (id) {
			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}
			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				product_id: id
			}
			this.axios.post('get-product', data)
				.then(resp => {
					let product = resp.data.items
					// console.log(product)

					let name_split = product.name.split(' - ')
					let name = ''
					name_split.forEach((name_part, i) => {
						if (i != name_split.length - 1) name += name_part
					})
					
					this.import_product.categories = product.categories.map(x => x.id)
					this.import_product.attributes = product.attributes
					this.import_product.brand = product.attributes?.find(attr => attr.name.toLowerCase() == 'brand')?.options[0] || null
					this.import_product.title = name
					this.import_product.description = product.description
					this.import_product.product_code = product.attributes?.find(attr => attr.name.toLowerCase() == 'product-code')?.options[0] || null
					this.import_images = product.images.map(x => x.src)

					this.import_product.type = product.type
					this.import_product.regular_price = product.regular_price
					this.import_product.stock_quantity = product.stock_quantity
				})
		},
		loadWooCategories (page = 1) {
			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}
			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				page
			}
			this.axios.post('store-categories', data)
				.then(resp => {
						// console.log('we are at page ' + page)
					if (page > 1) {
						// console.log('mpike sto if')
						this.categories = this.categories.concat(resp.data.categories)
					} else {
						// console.log('mpike sto else')
						this.categories = resp.data.categories
					}
					if (this.$flattenCategories(resp.data.categories).length > 99) this.loadWooCategories(page + 1)
					console.log('Store Categories loaded successfully.')
				})
				.catch(err => {
					this.$store.state.error = err
				})
		},
		loadWooAttributes () {
			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}
			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret')
			}
			this.axios.post('get-attributes', data)
				.then(resp => {
					this.attributes = resp.data.attributes
					console.log('Product Attributes loaded successfully.')
				})
				.catch(err => {
					this.$store.state.error = err
				})
		},
		arrayMove (arr, old_index, new_index) {
			while (old_index < 0) {
				old_index += arr.length
			}
			while (new_index < 0) {
				new_index += arr.length
			}
			if (new_index >= arr.length) {
				var k = new_index - arr.length + 1
				while (k--) {
					arr.push(undefined)
				}
			}
			arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
			return arr
		},
        imageUploaded (images) {
            this.import_images = this.import_images.concat(images)
        },
		importProduct () {
			if (!this.product_form) return this.$store.state.error = new Error('Πρέπει να συμπληρώσεις όλα τα απαραίτητα πεδία!')
			this.import_loading = true

			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}

			let product_data = { ...this.import_product }
			// product_data.image = this.import_images[0]
			product_data.images = [ ...this.import_images ]
			// product_data.images.shift()

			product_data.images = product_data.images.map(image => { return { src: image } })

			product_data.categories = product_data.categories.map(id => this.flat_categories.find(c => c.id == id))

			if (product_data.product_code) {
				product_data.name = `${product_data.title} - ${product_data.product_code}`
				product_data.attributes.find(x => x.name.toLowerCase() == 'product-code').options = [ product_data.product_code ]
				delete product_data.product_code
			}

			if (product_data.brand) {
				product_data.attributes.find(x => x.id == this.attr_brand.id).option = product_data.brand
				delete product_data.brand
			}

			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				product_id: this.$route.params.product_id,
				data: product_data
			}

			this.axios.post('update-product', data)
				.then(resp => {
					// console.log(resp.data)
					this.reset()
					this.import_loading = false

					this.success = {
						// edit_url: `${localStorage.getItem('wp_site_url')}wp-admin/post.php?post=${resp.data.product.id}&action=edit`,
						view_url: resp.data.product.permalink
					}

					if (this.$refs.barcodeField) this.$refs.barcodeField.$refs.input.focus()
					if (this.$refs.productCodeField) this.$refs.productCodeField.$refs.input.focus()
				})
				.catch(err => {
					this.$store.state.error = err
					this.import_loading = false
				})
		}
	}
}
</script>
