<template>
	<v-app>
		<AppBar @logOut="logOut()"></AppBar>
		<v-main>
			<router-view/>
		</v-main>
		<ErrorSnackbar v-model="error" :dismiss="true"></ErrorSnackbar>
	</v-app>
</template>

<style>
:root {
	scroll-padding-top: 96px;
}
html {
	scroll-behavior: smooth;
}
html, .v-application {
	font-size: 16px !important;
}
@media screen and (max-width: 800px) {
	html, .v-application {
		font-size: 14px !important;
	}
}
@media screen and (max-width: 600px) {
	html, .v-application {
		font-size: 13px !important;
	}
}
.center-all {
	display: flex;
	justify-content: center;
	align-items: center;
}
input {
	margin-left: 0 !important;
}
</style>

<script>
import AppBar from '@/components/Layout/AppBar'
import ErrorSnackbar from '@/components/Layout/ErrorSnackbar'
export default {
	name: 'App',
	components: {
		AppBar,
		ErrorSnackbar
	},
	data: () => ({
	}),
	computed: {
        error: {
            get () {
                return this.$store.state.error
            },
            set (v) {
				this.$store.state.error = null
            }
		}
	},
	mounted () {
		this.checkToken()
	},
	methods: {
		checkToken () {
			if (this.$route.name != 'Login' && !localStorage.getItem('sup_token')) this.$router.push('/login')
		},
		logOut () {
			localStorage.removeItem('sup_token')
			this.$router.push('/login')
		}
	}
}
</script>
