<template>
	<v-dialog v-model="dialog" :persistent="loading" content-class="rounded-xl" max-width="600">
		<v-card rounded="xl" :disabled="loading">
			<v-card-title class="pb-8">Ανέβασμα εικόνας</v-card-title>
			<v-card-text>
				<v-file-input v-model="files" chips multiple label="Εικόνα"></v-file-input>
			</v-card-text>
			<v-card-actions class="px-2">
				<v-btn rounded outlined color="primary" :disabled="loading" @click="dialog = false">Ακύρωση</v-btn>
				<v-spacer></v-spacer>
				<v-btn rounded depressed color="primary" :loading="loading" @click="$emit('uploaded', images); dialog = false; reset()">Ανέβασμα</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'ImageUpload',
	props: {
		value: {
			type: Boolean,
			required: false
		}
	},
	data: () => ({
		files: null,
		images: [],
		loading: false
	}),
	computed: {
        dialog: {
            get () {
                return !!this.value
            },
            set (v) {
                this.$emit('input', v)
            }
        }
	},
	watch: {
		files: {
			handler (files) {
				if (files && files.length > 0) this.uploadFiles()
			},
			deep: true
		}
	},
	methods: {
		reset () {
			this.files = null
			this.images = []	
		},
		uploadFiles() {
			this.loading = true
			const formData = new FormData()

			this.files.forEach((file, i) => {
				formData.append('files[' + i + ']', file)
			})

			this.axios.post("upload", formData)
				.then(resp => {
					this.images = resp.data.files.map(file => file.path) ?? []
					this.loading = false
				})
				.catch(err => {
					console.log(err)
					this.loading = false
				})
		}
	}
}
</script>