<template>
    <v-snackbar v-model="error_snackbar" class="error-snackbar" left color="red" transition="scroll-x-transition">
        <v-container fluid @click="error_snackbar = false">
            <v-row>
                <v-flex xs2 class="center-all">
                    <v-icon>mdi-alert</v-icon>
                </v-flex>
                <v-flex xs10>
                    <div class="font-weight-bold" v-html="error_data"></div>
                </v-flex>
            </v-row>
        </v-container>
    </v-snackbar>
</template>

<style>
.error-snackbar > .v-snack__wrapper {
    width: 100%;
    max-width: 12rem;
}
</style>

<script>
export default {
    name: 'ErrorSnackbar',
    components: {
    },
    props: {
        value: {
            type: Error,
            required: false
        },
        dismiss: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        error_snackbar: {
            get () {
                // console.log(JSON.stringify(this.value))
                return this.value
            },
            set (newValue) {
                if (newValue == false){
                    this.$emit('input', null)
                }
            }
        },
        error_data () {
            if (this.error_snackbar && this.error_snackbar.response && this.error_snackbar.response.data.message) {
                return this.error_snackbar.response.data.message
            } else if (this.error_snackbar && this.error_snackbar.response && this.error_snackbar.response.data.error) {
                return this.error_snackbar.response.data.error
            } else if (this.error_snackbar && this.error_snackbar.response && this.error_snackbar.response.data.errors) {
                return this.error_snackbar.response.data.errors.join('<br>')
            } else if (this.error_snackbar && this.error_snackbar.response) {
                return this.error_snackbar.response.message
            } else {
                return this.error_snackbar
            }
        }
    }
}
</script>