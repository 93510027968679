<template>
    <div>
        <v-dialog v-model="dialog" :persistent="add_variation_loading" max-width="900" content-class="rounded-xl">
            <v-card v-if="product">
                <v-card-title>
                    Προσθήκη Παραλλαγής του {{ product.name }}
                    <v-spacer></v-spacer>
                    <v-btn icon :disabled="add_variation_loading" @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="add_variation_form" :disabled="add_variation_loading">
                        <v-container fluid>
                            <v-layout row>
                                <v-flex xs10 md11>
                                    <v-overflow-btn editable v-model="add_variation.size" :items="attr_size_options" :loading="!attr_size_options" item-value="name" item-text="name" rounded filled label="Μέγεθος" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-overflow-btn>
                                </v-flex>
                                <v-flex xs2 md1 class="center-all">
                                    <v-btn icon @click="add_size_dialog = true" class="mb-6" :disabled="attributes_loading"><v-icon>mdi-plus</v-icon></v-btn>
                                </v-flex>
                            </v-layout>
                        </v-container>

                        <v-container fluid>
                            <v-layout row>
                                <v-flex xs10 md11>
                                    <v-overflow-btn editable v-model="add_variation.color" :items="attr_color_options" :loading="!attr_color_options" item-value="name" item-text="name" rounded filled label="Χρώμα" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-overflow-btn>
                                </v-flex>
                                <v-flex xs2 md1 class="center-all">
                                    <v-btn icon @click="add_color_dialog = true" class="mb-6" :disabled="attributes_loading"><v-icon>mdi-plus</v-icon></v-btn>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <v-text-field v-model="add_variation.price" rounded filled dense label="Τιμή" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-text-field>
                        <v-text-field v-model="add_variation.quantity" rounded filled dense label="Ποσότητα" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-text-field>
                        <v-text-field v-model="add_variation.sku" rounded filled dense label="Barcode"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn depressed rounded color="primary" :loading="add_variation_loading" @click="addVariation()"><v-icon left>mdi-plus</v-icon>Προσθήκη</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

		<AddAttributeDialog v-if="attr_size && attr_size.id" v-model="add_size_dialog" attribute="size" :attr_id="attr_size.id" @reloadAttributes="loadWooAttributes()"></AddAttributeDialog>
		<AddAttributeDialog v-if="attr_color && attr_color.id" v-model="add_color_dialog" attribute="color" :attr_id="attr_color.id" @reloadAttributes="loadWooAttributes()"></AddAttributeDialog>
    </div>
</template>

<style scoped>
</style>

<script>
import AddAttributeDialog from '@/components/AddAttributeDialog'
export default {
    name: 'AddVariation',
    components: {
		AddAttributeDialog
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        product: {
            type: Object,
            default: false
        }
    },
    data: () => ({
        categories: [],
        categories_loading: false,
        attributes: [],
        attributes_loading: false,

        add_variation_form: true,
        add_variation_loading: false,
        add_variation: {
            size: null,
            color: null,
            price: null,
            quantity: null,
            sku: null
        },
        add_size_dialog: false,
        add_color_dialog: false
    }),
    computed: {
        dialog: {
            get () {
                return this.value
            },
            set (v) {
                this.$emit('input', v)
            }
        },
        attr_color () {
			return this.attributes?.find(attr => attr.name.toLowerCase() == 'color')
		},
		attr_color_options () {
			return this.attr_color?.options?.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) || []
		},
		attr_size () {
			return this.attributes?.find(attr => attr.name.toLowerCase() == 'size')
		},
		attr_size_options () {
			return this.attr_size?.options?.sort((a,b) => (a.name > b.name) ? -1 : ((b.name > a.name) ? 1 : 0)) || []
		}
    },
    watch: {
        dialog (v) {
            if (!v) return
            this.loadWooCategories()
            this.loadWooAttributes()
        }
    },
    methods: {
        loadWooCategories (page = 1) {
			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}

            this.categories_loading = true

			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				page
			}
			this.axios.post('store-categories', data)
				.then(resp => {
					if (page > 1) {
						this.categories = this.categories.concat(resp.data.categories)
					} else {
						this.categories = resp.data.categories
					}
                    this.categories_loading = false
					if (this.$flattenCategories(resp.data.categories).length > 99) this.loadWooCategories(page + 1)
					console.log('Store Categories loaded successfully.')
				})
				.catch(err => {
					this.$store.state.error = err
                    this.categories_loading = false
				})
		},
		loadWooAttributes () {
			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}

            this.attributes_loading = true

			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret')
			}
			this.axios.post('get-attributes', data)
				.then(resp => {
					this.attributes = resp.data.attributes
					console.log('Product Attributes loaded successfully.')
                    this.attributes_loading = false
				})
				.catch(err => {
					this.$store.state.error = err
                    this.attributes_loading = false
				})
		},
        addVariation () {
			if (!this.add_variation_form) return this.$store.state.error = new Error('Πρέπει να συμπληρώσεις όλα τα απαραίτητα πεδία!')

			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}

			this.add_variation_loading = true

            let product_data = { ...this.add_variation }

			product_data.quantity = product_data.quantity ? parseInt(product_data.quantity) : 0

			product_data.color = {
				id: this.attr_color.id,
				name: this.attr_color.name,
				option: product_data.color
			}

			product_data.size = {
				id: this.attr_size.id,
				name: this.attr_size.name,
				option: product_data.size
			}

			product_data.product_id = this.product.id

            let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				data: product_data
			}

            this.axios.post('add-variation', data)
				.then(resp => {
                    this.add_variation_loading = false
                    this.dialog = false
                    this.add_variation = {
                        size: null,
                        color: null,
                        price: null,
                        quantity: null,
                        sku: null
                    }
                    this.$emit('reload')
					// console.log(resp.data)
                })
				.catch(err => {
					this.$store.state.error = err
                    this.add_variation_loading = false
				})
        }
    }
}
</script>