import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		suppliers: [
			{
				name: 'Apple',
				codename: 'apple',
				search_support: ['barcode'],
				download: false
			},
			{
				name: 'Admas',
				codename: 'admas',
				search_support: ['barcode'],
				download: false
			},
			{
				name: 'YSOBEL MORA',
				codename: 'ysobel',
				search_support: ['barcode'],
				download: false
			},
			{
				name: 'Luna',
				codename: 'luna',
				search_support: ['barcode'],
				download: true
			},
			{
				name: 'Vamp',
				codename: 'vamp',
				search_support: ['barcode'],
				download: true
			},
			{
				name: 'Calvin Klein',
				codename: 'calvin',
				search_support: ['barcode'],
				download: true
			},
			{
				name: 'Dorina',
				codename: 'dorina',
				search_support: ['product_code', 'barcode'],
				download: true
			},
			{
				name: 'Harmony',
				codename: 'harmony',
				search_support: ['product_code', 'barcode'],
				download: true
			},
			{
				name: 'Χειροκίνητη Εισαγωγή',
				codename: 'manual',
				search_support: ['product_code'],
				download: false
			}
		],
		error: null
	},
	mutations: {
	},
	actions: {
	},
	modules: {
	}
})
