<template>
	<div id="add-product">
		<v-container>
			<v-card v-if="!success" rounded="xl" class="my-4" :loading="!flat_categories || flat_categories.length < 1 || !attributes || attributes.length < 1">
				<v-card-title>Προσθήκη προϊόντος</v-card-title>
				<v-card-text v-if="flat_categories && flat_categories.length > 0 && attributes && attributes.length > 0" class="pt-0">
					<v-form v-model="product_form" :disabled="import_loading">
                        <v-img v-if="image_preview != null && import_images[image_preview]" :src="import_images[image_preview]" contain max-height="480" class="ma-4 mt-0 product-image elevation-4"></v-img>
                        <v-btn depressed rounded color="primary" class="mx-4 mb-4" @click="upload_image = true"><v-icon left>mdi-upload</v-icon>Ανέβασμα Εικόνας</v-btn>
                        <v-slide-group v-if="image_preview != null && import_images[image_preview]" v-model="image_preview" class="pb-4" center-active>
                            <v-slide-item v-for="(image, i) in import_images" :key="i" v-slot:default="{ active, toggle }">
                                <div class="text-center">
                                    <v-card class="ma-4" height="100" width="100" @click="!active ? toggle() : null">
                                        <v-img :src="image" :gradient="active ? 'to bottom, rgba(79, 101, 142, 0.8), rgba(79, 101, 142, 0.8)' : ''" height="100" width="100"></v-img>
                                    </v-card>
                                    <v-btn icon color="primary" :disabled="!import_images[i - 1]" @click="arrayMove(import_images, i, i - 1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
                                    <v-btn icon color="primary" :disabled="!import_images[i + 1]" @click="arrayMove(import_images, i, i + 1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
                                    <v-btn icon color="red" @click="import_images.splice(i, 1)"><v-icon>mdi-delete</v-icon></v-btn>
                                </div>
                            </v-slide-item>
                        </v-slide-group>
                        <v-overflow-btn editable v-model="import_product.categories" rounded filled label="Κατηγορία" :items="flat_categories" item-text="name" item-value="id" multiple :menu-props="{ bottom: true }" chips :rules="[ v => !!v || 'Απαιτείται συμπλήρωση', v => v && v.length > 0 || 'Απαιτείται τουλάχιστον 1 επιλογή' ]"></v-overflow-btn>
						<v-container fluid>
							<v-layout row>
								<v-flex xs10 md11>
									<v-overflow-btn editable v-model="import_product.brand" :items="attr_brand_options" item-value="name" item-text="name" rounded filled label="Μάρκα (Brand)" clearable></v-overflow-btn>
								</v-flex>
								<v-flex xs2 md1 class="center-all">
									<v-btn icon @click="add_brand_dialog = true" class="mb-6" :disabled="import_loading"><v-icon>mdi-plus</v-icon></v-btn>
								</v-flex>
							</v-layout>
						</v-container>
                        <v-text-field v-model="import_product.title" rounded filled dense label="Τίτλος" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-text-field>
                        <v-textarea v-model="import_product.description" rounded filled dense label="Περιγραφή" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-textarea>

						<v-container fluid>
							<v-layout row>
								<v-flex xs10 md11>
									<v-overflow-btn editable v-model="import_product.size" :items="attr_size_options" item-value="name" item-text="name" rounded filled label="Μέγεθος" clearable></v-overflow-btn>
								</v-flex>
								<v-flex xs2 md1 class="center-all">
									<v-btn icon @click="add_size_dialog = true" class="mb-6" :disabled="import_loading"><v-icon>mdi-plus</v-icon></v-btn>
								</v-flex>
							</v-layout>
						</v-container>

						<v-container fluid>
							<v-layout row>
								<v-flex xs10 md11>
									<v-overflow-btn editable v-model="import_product.color" :items="attr_color_options" item-value="name" item-text="name" rounded filled label="Χρώμα" clearable></v-overflow-btn>
								</v-flex>
								<v-flex xs2 md1 class="center-all">
									<v-btn icon @click="add_color_dialog = true" class="mb-6" :disabled="import_loading"><v-icon>mdi-plus</v-icon></v-btn>
								</v-flex>
							</v-layout>
						</v-container>
						<v-text-field v-model="import_product.price" rounded filled dense label="Τιμή" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-text-field>
						<v-text-field v-model="import_product.quantity" rounded filled dense label="Ποσότητα" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-text-field>
						<v-text-field v-model="import_product.sku" rounded filled dense label="Barcode"></v-text-field>
                        <v-text-field v-model="import_product.product_code" rounded filled dense label="Κωδικός Προϊόντος" :rules="[ v => !!v || 'Απαιτείται συμπλήρωση' ]"></v-text-field>
					</v-form>
				</v-card-text>
				<v-card-actions v-if="flat_categories && flat_categories.length > 0 && attributes && attributes.length > 1">
					<v-btn rounded outlined color="primary" class="px-4" :disabled="import_loading" @click="reset()">Επαναφορά</v-btn>
					<v-spacer></v-spacer>
					<v-btn rounded depressed color="primary" class="px-4" :loading="import_loading" @click="importProduct()">Εισαγωγή</v-btn>
				</v-card-actions>
			</v-card>
			<v-card v-if="success" rounded="xl">
				<v-card-title>Το προϊόν προστέθηκε με επιτυχία!</v-card-title>
				<v-card-actions>
					<v-btn v-if="success.edit_url" rounded outlined color="primary" class="px-4" :href="success.edit_url">
						<v-icon left>mdi-pencil</v-icon>
						Επεξεργασία
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn v-if="success.view_url" rounded depressed color="primary" class="px-4" :href="success.view_url" target="_blank">
						<v-icon left>mdi-eye</v-icon>
						Προβολή
					</v-btn>
				</v-card-actions>
			</v-card>
            <div v-if="success" class="center-all" style="width: 100%;">
                <v-btn rounded depressed color="primary" class="mt-6 px-4" @click="success = null">
                    <v-icon left>mdi-plus</v-icon>
                    Προσθήκη Νέου
                </v-btn>
            </div>
		</v-container>

        <ImageUpload v-model="upload_image" @uploaded="imageUploaded"></ImageUpload>
		<AddAttributeDialog v-if="attr_size && attr_size.id" v-model="add_size_dialog" attribute="size" :attr_id="attr_size.id" @reloadAttributes="loadWooAttributes()"></AddAttributeDialog>
		<AddAttributeDialog v-if="attr_color && attr_color.id" v-model="add_color_dialog" attribute="color" :attr_id="attr_color.id" @reloadAttributes="loadWooAttributes()"></AddAttributeDialog>
		<AddAttributeDialog v-if="attr_brand && attr_brand.id" v-model="add_brand_dialog" attribute="brand" :attr_id="attr_brand.id" @reloadAttributes="loadWooAttributes()"></AddAttributeDialog>
	</div>
</template>

<style scoped>
.product-image {
	border-radius: 8px;
}
</style>

<script>
import ImageUpload from '@/components/Layout/ImageUpload'
import AddAttributeDialog from '@/components/AddAttributeDialog'
export default {
	name: 'AddProduct',
	components: {
		ImageUpload,
		AddAttributeDialog
	},
	data: () => ({
        upload_image: false,

		import_product: {
			title: null,
			description: null,
			size: [],
			color: [],
			price: null,
			quantity: null,
			sku: null,
			product_code: null,
			image: null,
			images: [],
			categories: []
		},
		import_images: [],
		product_form: true,
		image_preview: 0,

		categories: [],
		attributes: [],

		add_size_dialog: false,
		add_color_dialog: false,
		add_brand_dialog: false,

		import_loading: false,

		success: null
	}),
	computed: {
		suppliers () {
			return this.$store.state.suppliers
		},
		flat_categories () {
			return this.$flattenCategories(this.categories)
		},
		selected_supplier_obj () {
			return this.$store.state.suppliers.find(s => s.codename == this.selected_supplier)
		},
		selected_variation_item () {
			return this.variation.items.find(item => item.id == this.variation.selected_item)
		},
		attr_color () {
			return this.attributes?.find(attr => attr.name.toLowerCase() == 'color')
		},
		attr_color_options () {
			return this.attr_color?.options.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) || []
		},
		attr_size () {
			return this.attributes?.find(attr => attr.name.toLowerCase() == 'size')
		},
		attr_size_options () {
			return this.attr_size?.options?.sort((a,b) => (a.name > b.name) ? -1 : ((b.name > a.name) ? 1 : 0)) || []
		},
		attr_brand () {
			return this.attributes?.find(attr => attr.name.toLowerCase() == 'brand')
		},
		attr_brand_options () {
			return this.attr_brand?.options?.sort((a,b) => (a.name > b.name) ? -1 : ((b.name > a.name) ? 1 : 0)) || []
		}
	},
	mounted () {
		this.loadWooCategories()
		this.loadWooAttributes()
	},
	methods: {
		reset () {
            this.import_product = {
				brand: null,
                title: null,
                description: null,
                size: null,
                color: null,
                price: null,
                quantity: null,
                sku: null,
                product_code: null,
                image: null,
                images: null,
                categories: null
            }
		},
		loadWooCategories (page = 1) {
			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}
			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				page
			}
			this.axios.post('store-categories', data)
				.then(resp => {
						// console.log('we are at page ' + page)
					if (page > 1) {
						// console.log('mpike sto if')
						this.categories = this.categories.concat(resp.data.categories)
					} else {
						// console.log('mpike sto else')
						this.categories = resp.data.categories
					}
					if (this.$flattenCategories(resp.data.categories).length > 99) this.loadWooCategories(page + 1)
					console.log('Store Categories loaded successfully.')
				})
				.catch(err => {
					this.$store.state.error = err
				})
		},
		loadWooAttributes () {
			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}
			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret')
			}
			this.axios.post('get-attributes', data)
				.then(resp => {
					this.attributes = resp.data.attributes
					console.log('Product Attributes loaded successfully.')
				})
				.catch(err => {
					this.$store.state.error = err
				})
		},
		arrayMove (arr, old_index, new_index) {
			while (old_index < 0) {
				old_index += arr.length
			}
			while (new_index < 0) {
				new_index += arr.length
			}
			if (new_index >= arr.length) {
				var k = new_index - arr.length + 1
				while (k--) {
					arr.push(undefined)
				}
			}
			arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
			return arr
		},
        imageUploaded (images) {
            this.import_images = this.import_images.concat(images)
        },
		importProduct () {
			if (!this.product_form) return this.$store.state.error = new Error('Πρέπει να συμπληρώσεις όλα τα απαραίτητα πεδία!')
			this.import_loading = true

			if (!localStorage.getItem('wp_site_url') || !localStorage.getItem('wp_consumer_key') || !localStorage.getItem('wp_consumer_secret')) {
				return this.$store.state.error = new Error('Πρέπει να ρυθμίσετε πρώτα την σύνδεση με το WooCommerce.')
			}

			let product_data = { ...this.import_product }
			product_data.image = this.import_images[0]
			product_data.images = [ ...this.import_images ]
			product_data.images.shift()

			product_data.quantity = product_data.quantity ? parseInt(product_data.quantity) : 0

			if (product_data.product_code) product_data.title = `${product_data.title} - ${product_data.product_code}`

			if (product_data.color && product_data.color.length > 0) {
				product_data.color = {
					id: this.attr_color.id,
					name: this.attr_color.name,
					option: product_data.color
				}
			} else {
				delete product_data.color
			}

			if (product_data.size && product_data.size.length > 0) {
				product_data.size = {
					id: this.attr_size.id,
					name: this.attr_size.name,
					option: product_data.size
				}
			} else {
				delete product_data.size
			}

			if (product_data.brand) {
				product_data.brand = {
					id: this.attr_brand.id,
					name: this.attr_brand.name,
					option: product_data.brand
				}
			} else {
				delete product_data.brand
			}

			let data = {
				url: localStorage.getItem('wp_site_url'),
				consumer_key: localStorage.getItem('wp_consumer_key'),
				consumer_secret: localStorage.getItem('wp_consumer_secret'),
				data: product_data
			}

			this.axios.post('add-product', data)
				.then(resp => {
					// console.log(resp.data)
					this.import_product = {
						brand: null,
						title: null,
						description: null,
						size: null,
						color: null,
						price: null,
						quantity: null,
						sku: null,
						product_code: null,
						image: null,
						images: [],
						categories: []
                    }
					this.import_loading = false

					this.success = {
						// edit_url: `${localStorage.getItem('wp_site_url')}wp-admin/post.php?post=${resp.data.product.id}&action=edit`, // WP Edit URL
						edit_url: `/edit/${resp.data.product.id}`,
						view_url: resp.data.product.permalink
					}
				})
				.catch(err => {
					this.$store.state.error = err
					this.import_loading = false
				})
		}
	}
}
</script>
