<template>
    <div id="login" class="fill-height center-all">
        <v-card rounded="xl" class="mx-2">
            <v-card-title>Σύνδεση</v-card-title>
            <v-card-subtitle>Παρακαλώ εισάγετε το token σας για την σύνδεση</v-card-subtitle>
            <v-card-text class="pb-0">
                <v-text-field v-model="token" rounded filled dense></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded depressed color="primary" class="px-4" @click="login()">Σύνδεση</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<style scoped>
</style>

<script>
export default {
    name: 'Login',
    data: () => ({
        token: null
    }),
    mounted () {
        this.checkToken()
    },
    methods: {
        checkToken () {
            if (localStorage.getItem('sup_token')) this.$router.push('/')
        },
        login () {
            localStorage.setItem('sup_token', this.token)
            this.$router.push('/')
        }
    }
}
</script>
