import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home'
import AddProduct from '@/views/AddProduct'
import EditProduct from '@/views/EditProduct'
import SupplierImport from '@/views/SupplierImport'
import Settings from '@/views/Settings'
import Login from '@/views/Login'

import store from '../store'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			censor: true
		}
	},
	{
		path: '/add',
		name: 'AddProduct',
		component: AddProduct,
		meta: {
			censor: true
		}
	},
	{
		path: '/edit/:product_id',
		name: 'EditProduct',
		component: EditProduct,
		meta: {
			censor: true
		}
	},
	{
		path: '/import',
		name: 'SupplierImprort',
		component: SupplierImport
	},
	{
		path: '/settings',
		name: 'Settings',
		component: Settings
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	if(to.meta.censor && !localStorage.getItem('sup_token')?.startsWith('CgvrUSo')){
		next({name: 'SupplierImprort' })
	} else {
		next()
	}
})

export default router
