<template>
    <v-app-bar app color="primary" dark>
        <v-toolbar-title><router-link to="/">{{ this.$appName }}</router-link></v-toolbar-title>

        <v-spacer></v-spacer>

        <v-menu v-if="$route.name != 'Login'" bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
            </template>

            <v-list>
                <template v-for="(item, i) in menu">
                    <v-list-item v-if="item.emit" :key="i" @click="$emit(item.emit)">
                        <v-icon left v-if="item.icon">{{ item.icon }}</v-icon>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-else-if="item.to" :key="i" :to="item.to">
                        <v-icon left v-if="item.icon">{{ item.icon }}</v-icon>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-else :key="i">
                        <v-icon left v-if="item.icon">{{ item.icon }}</v-icon>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<style scoped>
.v-toolbar__title a {
    color: #ffffff !important;
    text-decoration: none !important;
}
</style>

<script>
export default {
    name: 'AppBar',
    data: () => ({
		menu: [
			{
                icon: 'mdi-cog',
				title: 'Ρυθμίσεις',
				to: '/settings'
			},
			{
                icon: 'mdi-logout',
				title: 'Αποσύνδεση',
				emit: 'logOut'
			}
		]
    })
}
</script>